import React from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Routes from "./pages/Routes/Routes.js";
import Login from "./pages/Authentication/Login.js";
import Sidenav from "./components/SideNav";
import Header from "./components/Header";

function App() {
  React.useEffect(() => {
    try {
      const adminData = JSON.parse(localStorage.getItem("Admin"));
      if (Array.isArray(adminData) && !!adminData?.length) {
        const faviconLogoFileSource =
          adminData[0]?.media?.faviconLogoFileSource ?? null;
        if (faviconLogoFileSource) {
          console.log("Favicon logo source is: ", faviconLogoFileSource);
          let link = document.querySelector("link[rel~='icon']");
          if (!link) {
            link = document.createElement("link");
            link.rel = "icon";
            document.getElementsByTagName("head")[0].appendChild(link);
          }
          link.href = faviconLogoFileSource;
        }
      }
    } catch (e) {}
  }, []);
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={LoginContainer} />
        <Route component={DefaultContainer} />
      </Switch>
    </Router>
  );
}

const LoginContainer = () => (
  <div className="container">
    <Route exact path="/" component={Login} />
  </div>
);

const DefaultContainer = () => (
  <div>
    <div className="App">
      <div className="backdrop"></div>
      <div className="row row-main">
        <Sidenav />
        <div className="col-lg-10 col-md-8 col-sm-8 ">
          <Header />
          <Route component={Routes} />
        </div>
      </div>
    </div>
  </div>
);

// const OutletAdminContainer = () => (
//   <div>
//     <div className="App">
//       <div className="backdrop"></div>
//       <div className="row row-main">
//         {/* <Sidenav /> */}
//         <div className="col-lg-10 col-md-8 col-sm-8">
//           <Header />
//           <Route component={OutletAdminRoutes} />
//         </div>
//       </div>
//     </div>
//   </div>
// )

export default App;
