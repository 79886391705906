import "./inventory.css";
import React, { Fragment, useRef } from "react";
import { useState, useEffect } from "react";
import url from "../../../config/axios";
import { useHistory, useLocation } from "react-router-dom";
// import AddInventory from "../../../components/OutletAdminComponents/AddInventory";
// import EditModal from "../../../components/Inventory/EditModal";
import blank from "../../../assets/defaultImage.jpg";
import InvokeLayoutComponent from "../../../components/common/InvokeLogout";
import Popup from "reactjs-popup";
import debounce from "../../../helpers/debounce";
import { serializeQueryStringsFromObject } from "../../../helpers/serializeToQueryStrings";
import { CircularProgress, LinearProgress } from "@mui/material";
export default function Index() {
  // eslint-disable-next-line no-unused-vars
  const history = useHistory();
  const [inventory, setInventory] = useState([]);
  //   const [addModal, setAddModal] = useState(false);
  //   const [editView, setEditView] = useState(false);
  //   const [selectedProduct] = useState(null);
  //   const admin = JSON.parse(localStorage.getItem("Admin"));

  const [invokeLogOut, setInvokeLogout] = useState(false);
  const initialPaginationData = {
    page: 1,
    limit: 100,
    latestFirst: 1,
    search: "",
  };
  const [queryData, setQueryData] = useState({
    ...initialPaginationData,
  });
  const notFoundRef = useRef();
  const [searchTerm, setSearchTerm] = useState("");
  const [previousSearchTerm, setPreviousSearchTerm] = useState("");
  //whole editable object will be place here
  const [currentEditingIndex, setCurrentEditingIndex] = useState(null);
  const performSearch = debounce(() => {
    if ((searchTerm + "").trim() !== (previousSearchTerm + "").trim()) {
      setPreviousSearchTerm(searchTerm);
      handleChange("search", searchTerm, true);
    } else if (searchTerm === "") {
      setPreviousSearchTerm("");
      handleChange("search", "", true);
    }
  }, 1500);
  useEffect(
    () => {
      if (parsed && !loading) {
        performSearch();
      }
    },
    //eslint-disable-next-line
    [searchTerm]
  );
  //parse passed queries from the URL first
  const { search } = useLocation();
  const [parsed, setParsed] = useState(false);
  useEffect(
    () => {
      const query = new URLSearchParams(search);

      let page =
        Number.isNaN(query.get("page")) || !query.get("page")
          ? 1
          : +query.get("page");
      let limit =
        Number.isNaN(query.get("limit")) || !query.get("limit")
          ? 100
          : +query.get("limit");
      let latestFirst =
        Number.isNaN(query.get("latestFirst")) || !query.get("latestFirst")
          ? 1
          : +query.get("latestFirst");
      let searchParams = query.get("search");
      let queryParams = {
        ...queryData,
        page,
        limit,
        latestFirst,
        search: searchParams,
      };
      setSearchTerm(searchParams);
      setQueryData({ ...queryParams });
      setParsed(true);
    },
    //eslint-disable-next-line
    []
  );

  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    perPage: 30,
    totalPages: 0,
    total: 0,
  });
  const [loading, setLoading] = useState(true);

  //call to fetch data after parsing
  useEffect(
    () => {
      if (parsed) {
        GetAllProducts({ ...queryData });
      }
    },
    //eslint-disable-next-line
    [parsed, queryData]
  );

  /**
   *
   * @param {string} propName property to change
   * @param {*} propValue corresponding value of that property
   * @param {*} flush changes every other values to initial data
   */
  const handleChange = (propName, propValue, flush = false) => {
    if (currentEditingIndex === null) {
      let query = {};
      if (flush) {
        query = {
          ...initialPaginationData,
          [propName]: propValue,
        };
      } else {
        query = { ...queryData, [propName]: propValue };
      }
      const queryString = serializeQueryStringsFromObject(query);
      history.push(`/inventory-outlet${queryString}`);
      setQueryData({ ...query });
    }
  };

  const GetAllProducts = () => {
    setLoading(true);
    let queryParams = { ...queryData };
    //sanity
    Object.keys(queryParams).forEach((key) => {
      if (!queryParams[key]) {
        delete queryParams[key];
      }
    });
    url
      .get(`/v2/admin/inventories/paginated`, {
        params: {
          ...queryParams,
        },
      })
      .then(async (res) => {
        // console.log('res', res)
        if (res.data.Message === "Success") {
          if (!res.data?.data?.inventories?.length) {
            notFoundRef.current.open();
          }
          setInventory(res.data?.data?.inventories);
          console.log(res?.data?.data?.paginationData);
          setPaginationData({
            ...paginationData,
            ...res?.data?.data?.paginationData,
          });
        } else {
          console.error(res);
        }
        setLoading(false);
      })
      .catch((e) => {
        if (+e?.response?.status === 401) {
          setInvokeLogout(true);
        }
        setLoading(false);
        console.error(e?.response);
      });
  };

  //   const updateLocalData = (invent, i) => {
  //     // const index = inventory.findIndex((inv) => inv.productId === invent.productId);
  //     let obj = invent;
  //     const new_obj = { ...obj, featuredProduct: !obj.feafeaturedProduct };
  //     // console.log(new_obj, 'boj');
  //     let newArr = [...inventory]; // copying the old datas array
  //     newArr[i] = new_obj; // replace e.target.value with whatever you want to change it to
  //     setInventory(newArr);
  //   };

  const updateInventory = async (outletId, productId, featuredOne) => {
    console.log(outletId, productId, featuredOne);

    let toSet = [
      ...inventory.map((obj, i) =>
        obj.productID === currentEditingIndex?.productID
          ? { ...obj, featuredProduct: !obj.featuredProduct }
          : obj
      ),
    ];
    await url
      .put(`/inventory/?productID=${productId}&outletChainID=${outletId}`, {
        featuredProduct: featuredOne,
      })
      .then(async (res) => {
        console.log("updated", res.data);
        if (res.data.Message === "Success") {
          await setInventory(toSet);
          await setCurrentEditingIndex(null);
        } else {
          setCurrentEditingIndex(null);
          alert(res.data.data);
        }
      })
      .catch((e) => console.error(e));
  };

  //update
  useEffect(
    () => {
      if (!!currentEditingIndex) {
        updateInventory(
          currentEditingIndex.outletChainID,
          currentEditingIndex.productID,
          !currentEditingIndex.featuredProduct
        );
      }
    },
    //eslint-disable-next-line
    [currentEditingIndex]
  );

  return (
    <div className="row">
      <div className="col-md-12">
        {/* <button
          className="btn btn-primary btn-pro"
          onClick={() => setAddModal(!addModal)}
        >
          Add Product
        </button> */}

        <div className="card">
          {loading ? (
            <div
              className="row"
              style={{
                padding: "1em",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <Fragment>
              <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control table-search"
                          placeholder="Search Product"
                          aria-label="Input group example"
                          aria-describedby="btnGroupAddon"
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <div className="input-group-prepend">
                          <div
                            className="input-group-text table-search-icon"
                            id="btnGroupAddon"
                          >
                            <i className="fas fa-search"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2 filters-bar">
                      <div className="dropdown">
                        {/* <a
                      className="btn cannaby-light"
                      href="/"
                      role="button"
                      id="dropdownMenuLink"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="fas fa-filter"></i>
                    </a> */}

                        <div
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuLink"
                        >
                          <a className="dropdown-item" href="/">
                            Action
                          </a>
                          <a className="dropdown-item" href="/">
                            Another action
                          </a>
                          <a className="dropdown-item" href="/">
                            Something else here
                          </a>
                        </div>
                      </div>
                      <div className="dropdown">
                        {/* <a
                      className="btn cannaby-light"
                      href="/"
                      role="button"
                      id="dropdownMenuLink"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="fas fa-filter"></i>
                    </a> */}

                        <div
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuLink"
                        >
                          <a className="dropdown-item" href="/">
                            Action
                          </a>
                          <a className="dropdown-item" href="/">
                            Another action
                          </a>
                          <a className="dropdown-item" href="/">
                            Something else here
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4 pagination">
                      {paginationData.totalPages !== 0 &&
                        paginationData.currentPage <=
                        paginationData.totalPages && (
                          <Fragment>
                            <span>
                              {`${paginationData.perPage *
                                (paginationData.currentPage - 1) +
                                1
                                } - ${paginationData.perPage *
                                (paginationData.currentPage - 1) +
                                inventory.length
                                } of ${paginationData.total ?? 0}`}
                            </span>
                            <div
                              className={`btn ${paginationData.currentPage <= 1
                                ? ""
                                : "cannaby-light"
                                }`}
                              onClick={() => {
                                if (!(paginationData.currentPage <= 1)) {
                                  handleChange(
                                    "page",
                                    paginationData.currentPage - 1
                                  );
                                }
                              }}
                              role="button"
                            >
                              <i className="fas fa-chevron-left"></i>
                            </div>
                            <div
                              onClick={() => {
                                if (
                                  !(
                                    paginationData.currentPage >=
                                    paginationData.totalPages
                                  )
                                ) {
                                  handleChange(
                                    "page",
                                    paginationData.currentPage + 1
                                  );
                                }
                              }}
                              className={`btn ${paginationData.currentPage >=
                                paginationData.totalPages
                                ? ""
                                : "cannaby-light"
                                }`}
                              role="button"
                            >
                              <i className="fas fa-chevron-right"></i>
                            </div>
                          </Fragment>
                        )}
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <table className="table table-striped-cust">
                    <thead>
                      <tr>
                        <th scope="col">Name</th>

                        <th scope="col">Stock</th>
                        <th scope="col">Price</th>
                        <th scope="col">Brand</th>
                        <th scope="col">Category</th>
                        <th scope="col">Featured Product</th>
                        {/* <th scope="col">Status</th> */}
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {inventory.map((inv, i) => {
                        return (
                          <tr key={i}>
                            <td colSpan="" style={{ display: "flex" }}>
                              <div style={{ width: "300px", display: "flex" }}>
                                <img
                                  img
                                  alt="yo1"
                                  src={
                                    inv.product.image
                                      ? inv.product.image
                                      : blank
                                  }
                                  className="img-fluid"
                                  width="40"
                                  style={{ height: "40px" }}
                                />
                                <span>{inv.product.name}</span>
                              </div>
                            </td>

                            <td>{Math.floor(inv.quantity)}</td>
                            <td>
                              {inv.variants.length > 0
                                ? Number(inv.variants[0].price).toLocaleString(
                                  "fullwide",
                                  { useGrouping: false }
                                )
                                : Number(inv.originalPrice).toLocaleString(
                                  "fullwide",
                                  { useGrouping: false }
                                )}
                            </td>
                            <td
                              style={
                                inv.product.brandName
                                  ? {}
                                  : { paddingLeft: "30px" }
                              }
                            >
                              {inv.product.brandName
                                ? inv.product.brandName
                                : "-"}
                            </td>
                            <td className="status">
                              {inv.product.category.length
                                ? inv.product.category[0].name
                                : ""}
                            </td>
                            <td>
                              {inv?.productID ===
                                currentEditingIndex?.productID ? (
                                <LinearProgress color="success" />
                              ) : (
                                <label className="switch">
                                  <input
                                    type="checkbox"
                                    checked={inv.featuredProduct}
                                    disabled={currentEditingIndex !== null}
                                    onChange={async () => {
                                      //   updateLocalData(inv, i);
                                      if (currentEditingIndex === null) {
                                        setCurrentEditingIndex(inv);
                                      }
                                    }}
                                  />
                                  <span className="slider round"></span>
                                </label>
                              )}
                            </td>
                            {/* <td className="status">{inv.status ? <span>Not Published</span> : <span>Published</span>}</td> */}
                            <td>
                              {/* <i
                            onClick={() => moveToEdit(inv)}
                            className="fas fa-pen"
                          ></i> */}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </Fragment>
          )}
        </div>
      </div>
      {/* {addModal && (
        <AddInventory
          setAddModal={setAddModal}
          getInventory={getInventory}
          setInventory={setInventory}
        />
      )}
      {editView && (
        <EditModal
          closeModal={setEditView}
          GetAllInventory={getInventory}
          product={selectedProduct}
        />
      )} */}
      {invokeLogOut && <InvokeLayoutComponent />}
      <Popup
        ref={notFoundRef}
        position="center"
        onClose={() => {
          notFoundRef.current.close();
          setSearchTerm("");
        }}
      >
        <div style={{ textAlign: "center" }}>
          <i
            style={{
              color: "red",
              fontSize: "70px",
            }}
            // style={{
            //   color: "green",
            //   fontSize: "60px",
            //   border: "10px solid green",
            //   borderRadius: "50px",
            //   padding: "10px",
            // }}
            class="fa fa-ban"
          ></i>
          <h2 className="popup-heading" style={{ marginTop: "20px" }}>
            Failed
          </h2>
          <p>Product(s) not found</p>
          <button
            className="btn btn-cannaby"
            style={{ marginTop: "0" }}
            onClick={() => {
              notFoundRef.current.close();
              setSearchTerm("");
            }}
          >
            OK
          </button>
        </div>
      </Popup>
    </div>
  );
}
