/* eslint-disable no-unused-vars */
import "./dashboard.css";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import url from "../../config/axios";
import {
  XYPlot,
  XAxis,
  YAxis,
  VerticalGridLines,
  LineSeries,
  HorizontalGridLines,
  VerticalBarSeries,
  VerticalBarSeriesCanvas,
  LabelSeries,
} from "react-vis";
import Chart, { Legend } from "chart.js/auto";
import { Line, Bar } from "react-chartjs-2";
import blank from "../../assets/defaultImage.jpg";
import moment from "moment";

export default function Index() {
  const history = useHistory();
  const [revenue, setRevenue] = useState([]);
  const [totalRegistrations, setTotalRegistrations] = useState("");
  const [totalOrders, setTotalOrders] = useState("");
  const [totalRevenue, setTotalRevenue] = useState("");
  const [totalProducts, setTotalProducts] = useState("");
  const [productsByRevenue, setProductsByRevenue] = useState([]);
  const [productsByCount, setProductsByCount] = useState([]);
  const [totalUsersChart, setTotalUsersChart] = useState([]);
  const [totalOrdersChart, setTotalOrdersChart] = useState([]);
  const [totalOrdersChartMonthly, setTotalOrdersChartMonthly] = useState([]);
  const [totalRevenueChart, setTotalRevenueChart] = useState([]);
  const [totalRevenueChartMonthly, setTotalRevenueChartMonthly] = useState([]);
  const [totalRevenueChartFinal, setTotalRevenueChartFinal] = useState([
    { x: "June 2", y: 10 },
    { x: "June 3", y: 5 },
    { x: "June 4", y: 15 },
    { x: "June 5", y: 25 },
    { x: "June 6", y: 30 },
  ]);
  const [totalOrdersChartFinal, setTotalOrdersChartFinal] = useState([
    { x: "June 2", y: 10 },
    { x: "June 3", y: 5 },
    { x: "June 4", y: 15 },
    { x: "June 5", y: 25 },
    { x: "June 6", y: 30 },
  ]);

  const [totalUsersChartFinal, setTotalUsersChartFinal] = useState([
    { x: "June 2", y: 10 },
    { x: "June 3", y: 5 },
    { x: "June 4", y: 15 },
    { x: "June 5", y: 25 },
    { x: "June 6", y: 30 },
  ]);

  const greenData = [
    { x: "A", y: 10 },
    { x: "B", y: 5 },
    { x: "C", y: 15 },
  ];
  const [useCanvas] = useState(false);

  const labelData = greenData.map((d, idx) => ({
    x: d.x,
    y: Math.max(greenData[idx].y),
  }));
  // const RevenueLabelData = totalRevenueChart.map((d, idx) => ({
  //     x: d.price,
  //     y: idx.date
  // }))
  const BarSeries = useCanvas ? VerticalBarSeriesCanvas : VerticalBarSeries;

  const month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const date = new Date();

  useEffect(() => {
    getDashboardData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  // let revenue = findOcc(totalRevenueChart, "date", "price");
  // let orders = findOcc(totalOrdersChart, "date", "price");
  //     let users = findOcc(totalUsersChart, "date");
  //     console.log(revenue, orders, users, 'response');
  // setTotalRevenueChart(revenue);
  // setTotalUsersChart(users);
  // setTotalOrdersChart(orders);
  // }, [])

  //Array, Key, key
  function findOcc(arr, date, price) {
    let arr2 = [];
    // console.log(arr, 'x');

    arr.forEach((x) => {
      if (
        arr2.some((val) => {
          return val[date] === x[date];
        })
      ) {
        // If yes! then increase the occurrence by 1
        arr2.forEach((k) => {
          if (k[date] === x[date]) {
            k["occurrence"]++;
            k["price"] += x[price];
            k["y"] += x[price];
          }
        });
      } else {
        // If not! Then create a new object initialize
        // it with the present iteration key's value and
        // set the occurrence to 1
        let a = {};
        a[date] = x[date];
        a["occurrence"] = 1;
        a["price"] = x[price];
        // a["x"] = x[date];
        // a["y"] = x[price];
        arr2.push(a);
      }
    });
    // console.log(arr2, 'occurence');
    return arr2;
  }

  const getDashboardData = async () => {
    await url
      .get("/dashboard")
      .then((res) => {
        console.log("res dashboard:", res.data);
        if (res.data.Message === "Success") {
          setTotalOrders(res.data.data.totalOrders);
          setTotalRegistrations(res.data.data.totalRegistrations);
          setTotalProducts(res.data.data.totalProducts);
          setTotalRevenue(res.data.data.totalRevenue);
          setProductsByRevenue(res.data.data.topSellingByRevenue);
          setProductsByCount(res.data.data.topSellingByCount);

          // setTotalUsersChart(res.data.data.userRegistrationsChart);
          // setTotalOrdersChart(res.data.data.totalOrdersChart);
          // setTotalRevenueChart(res.data.data.totalRevenueChart);

          let users = findOcc(res.data.data.userRegistrationsChart, "date");
          let revenue = findOcc(
            res.data.data.totalRevenueChartWeekly,
            "date",
            "price"
          );
          let revenueMonthly = findOcc(
            res.data.data.totalRevenueChartMonthly,
            "date",
            "price"
          );
          let orders = findOcc(
            res.data.data.totalOrdersChartWeekly,
            "date",
            "price"
          );
          let ordersMonthly = findOcc(
            res.data.data.totalOrdersChartMonthly,
            "date",
            "price"
          );
          setTotalUsersChart(users);
          setTotalRevenueChart(revenue);
          setTotalOrdersChart(orders);
          setTotalOrdersChartMonthly(ordersMonthly);
          setTotalRevenueChartMonthly(revenueMonthly);
          console.log(revenue, orders, users, "response");
          let revenueArr = revenueMonthly.map(({ x, y }) => {
            return { x: x, y: ~~y };
          });
          let OrderArr = ordersMonthly.map(({ x, y }) => {
            return { x: x, y: ~~y };
          });
          console.log(revenueArr, "array", OrderArr);
          setTotalRevenueChartFinal(revenueArr);
          setTotalOrdersChartFinal(OrderArr);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // const weeklyMonthy = (e) => {
  //   let OrderArr = totalOrdersChart.map(({ x, y }) => {
  //     return { x: x, y: y };
  //   });
  //   if (e === "LAST MONTH") {
  //     setTotalOrdersChartFinal([
  //       { x: "January", y: 10 },
  //       { x: "February", y: 5 },
  //       { x: "March", y: 15 },
  //       { x: "May", y: 25 },
  //       { x: "June", y: 30 },
  //       { x: "July", y: 10 },
  //       { x: "August", y: 20 },
  //       { x: "September", y: 15 },
  //       { x: "October", y: 10 },
  //     ]);
  //   } else {
  //     setTotalOrdersChartFinal(
  //       OrderArr.slice(Math.max(OrderArr.length - 5, 1))
  //     );
  //   }
  // };

  const RevenueWeeklyMonthy = (e) => {
    let revenueArr = totalRevenueChart.map(({ x, y }) => {
      return { x: x, y: y };
    });

    if (e === "LAST MONTH") {
      // setTotalRevenueChartFinal([
      //   { x: "January", y: 10 },
      //   { x: "February", y: 5 },
      //   { x: "March", y: 15 },
      //   { x: "May", y: 25 },
      //   { x: "June", y: 30 },
      //   { x: "July", y: 10 },
      //   { x: "August", y: 20 },
      //   { x: "September", y: 15 },
      //   { x: "October", y: 10 },
      // ]);
      setTotalRevenueChartFinal(totalRevenueChartMonthly);
    } else {
      setTotalRevenueChartFinal(totalRevenueChart);
    }
  };

  const OrderWeeklyMonthy = (e) => {
    let OrderArr = totalOrdersChart.map(({ x, y }) => {
      return { x: x, y: y };
    });
    if (e === "LAST MONTH") {
      setTotalOrdersChartFinal(totalOrdersChartMonthly);
    } else {
      setTotalOrdersChartFinal(totalOrdersChart);
    }
  };

  function checkDate(val) {
    var x = -7;
    x = x + val;
    var date = new Date();
    date.setDate(date.getDate() + x);
    return date.getDate();
  }

  function validateWeeklyOrders(val, check) {
    /* eslint eqeqeq: 0 */

    var x = 0;
    totalRevenueChart.forEach((item) => {
      console.log(val, "dates");

      if (val == item.date.substring(4, 6)) {
        if (check === "order") {
          x = item.occurrence;
        } else {
          x = item.price;
        }
      }
    });

    return x;
  }

  const barOrderDataWeekly = {
    labels: [
      checkDate(0) + " " + month[date.getMonth()],
      checkDate(1) + " " + month[date.getMonth()],
      checkDate(2) + " " + month[date.getMonth()],
      checkDate(3) + " " + month[date.getMonth()],
      checkDate(4) + " " + month[date.getMonth()],
      checkDate(5) + " " + month[date.getMonth()],
      checkDate(6) + " " + month[date.getMonth()],
    ],
    datasets: [
      {
        label: "Last Week",
        data: [
          validateWeeklyOrders(checkDate(0), "order"),
          validateWeeklyOrders(checkDate(1), "order"),
          validateWeeklyOrders(checkDate(2), "order"),
          validateWeeklyOrders(checkDate(3), "order"),
          validateWeeklyOrders(checkDate(4), "order"),
          validateWeeklyOrders(checkDate(5), "order"),
          validateWeeklyOrders(checkDate(6), "order"),
        ],
        fill: true,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
      },
    ],
  };

  const barRevenueDataWeekly = {
    labels: [
      checkDate(0) + " " + month[date.getMonth()],
      checkDate(1) + " " + month[date.getMonth()],
      checkDate(2) + " " + month[date.getMonth()],
      checkDate(3) + " " + month[date.getMonth()],
      checkDate(4) + " " + month[date.getMonth()],
      checkDate(5) + " " + month[date.getMonth()],
      checkDate(6) + " " + month[date.getMonth()],
    ],
    datasets: [
      {
        label: "Last Week",
        data: [
          validateWeeklyOrders(checkDate(0), "revenue"),
          validateWeeklyOrders(checkDate(1), "revenue"),
          validateWeeklyOrders(checkDate(2), "revenue"),
          validateWeeklyOrders(checkDate(3), "revenue"),
          validateWeeklyOrders(checkDate(4), "revenue"),
          validateWeeklyOrders(checkDate(5), "revenue"),
          validateWeeklyOrders(checkDate(6), "revenue"),
        ],
        fill: true,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
      },
    ],
  };

  function validateMonth(val) {
    var x = 0;
    totalUsersChart.forEach((item) => {
      if (val === item.date) {
        x = item.occurrence;
      }
    });
    return x;
  }

  const lineData = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Last Year",
        data: [
          validateMonth("Jan"),
          validateMonth("Feb"),
          validateMonth("Mar"),
          validateMonth("Apr"),
          validateMonth("May"),
          validateMonth("Jun"),
          validateMonth("Jul"),
          validateMonth("Aug"),
          validateMonth("Sep"),
          validateMonth("Oct"),
          validateMonth("Nov"),
          validateMonth("Dec"),
        ],
        fill: true,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
      },
    ],
  };

  console.log(totalRevenueChart, "Revenue Chart");

  return (
    <>
      <div className="row">
        <div className="col-md-3 padding-0" style={{ paddingLeft: "15px" }}>
          <div className="card-dashboard">
            {/* <div className="card-dash-icon"><span><LocalOfferIcon /></span></div> */}
            <div></div>
            <div className="card-dash-text">{totalRegistrations} </div>
            <br />
            <div className="card-text">Total Registrations</div>
          </div>
        </div>
        <div className="col-md-3 padding-0">
          <div className="card-dashboard card-cat">
            {/* <div className="card-dash-icon"><span><CategoryIcon /></span></div> */}
            <div></div>
            <div className="card-dash-text">{totalOrders}</div>
            <br />
            <div className="card-text">Total Orders</div>
          </div>
        </div>
        <div className="col-md-3 md-offset-1 padding-0">
          <div className="card-dashboard card-cat">
            {/* <div className="card-dash-icon"><span><BrandingWatermarkIcon /></span></div> */}
            <div></div>
            <div className="card-dash-text">
              {(Math.round(totalRevenue * 100) / 100).toFixed(2)}
            </div>
            <br />
            <div className="card-text">Total Revenue</div>
          </div>
        </div>
        <div
          className="col-md-3 md-offset-1 padding-0"
          style={{ paddingRight: "15px" }}
        >
          <div className="card-dashboard card-cat">
            {/* <div className="card-dash-icon"><span><BrandingWatermarkIcon /></span></div> */}
            <div></div>
            <div className="card-dash-text">{totalProducts}</div>
            <br />
            <div className="card-text">Total Products</div>
          </div>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-md-12 ">
          <div className="card-dashboard">
            <div className="d-flex flex-row">
              <h6 className="mr-auto p-2">USER REGISTRATIONS</h6>

              {/* <select style={{ height: '30px' }} onChange={(e) => weeklyMonthy(e.target.value)}>
                  <option value="LAST MONTH">LAST MONTH</option>
                  <option value="LAST WEEK">LAST WEEK</option>
              </select> */}
            </div>

            <div>
              <Line data={lineData} />
            </div>
            {/* <XYPlot width={1100} height={300}>
              <XAxis />
              <YAxis />
              <LineSeries
                className="first-series"
                data={[
                  { x: 1, y: 3 },
                  { x: 2, y: 5 },
                  { x: 3, y: 15 },
                  { x: 4, y: 12 },
                ]}
                style={{
                  strokeLinejoin: "round",
                  strokeWidth: 4,
                }}
              />
            </XYPlot> */}
          </div>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-md-6">
          <div className="card-dashboard">
            <div className="d-flex flex-row">
              <h6 className="mr-auto p-2">TOTAL ORDERS</h6>
              {/*
               */}
              {/* <button className="btn p-2">LAST WEEK</button> */}
            </div>
            {/* <XYPlot xType="ordinal" width={500} height={300} xDistance={100}>
              <VerticalGridLines />
              <HorizontalGridLines />
              <XAxis />
              <YAxis />
              <BarSeries
                className="vertical-bar-series-example"
                data={totalOrdersChartFinal}
              /> */}
            {/* <LabelSeries data={labelData}  /> */}
            {/* </XYPlot> */}
            <Bar data={barOrderDataWeekly} />
          </div>
        </div>
        <div className="col-md-6 ">
          <div className="card-dashboard">
            <div className="d-flex flex-row">
              <h6 className="mr-auto p-2">TOTAL REVENUE</h6>
              {/* <select
                style={{ height: "30px" }}
                onChange={(e) => RevenueWeeklyMonthy(e.target.value)}
              >
                <option value="LAST MONTH">LAST MONTH</option>
                <option value="LAST WEEK">LAST WEEK</option>
              </select> */}
              {/* <button className="btn p-2">LAST WEEK</button> */}
            </div>
            {/* <XYPlot xType="ordinal" width={500} height={300} xDistance={100}>
              <VerticalGridLines />
              <HorizontalGridLines />
              <XAxis margin={{ left: 75 }} width={100} height={100} />
              <YAxis />
              <BarSeries
                className="vertical-bar-series-example"
                data={totalRevenueChartFinal}
              /> */}
            {/* <LabelSeries data={greenData} /> */}
            {/* </XYPlot> */}
            <Bar data={barRevenueDataWeekly} />
          </div>
        </div>
      </div>

      <div className="row mt-3 mb-3">
        <div className="col-md-6">
          <div className="card-chart">
            <div className="d-flex flex-row">
              <h6 className="mr-auto p-2">TOP SELLING PRODUCTS BY REVENUE</h6>
              {/* <button className="btn p-2">View All</button> */}
            </div>

            {productsByRevenue.slice(0, 5).map((revenue, i) => (
              <>
                <div className="pt-2 d-flex flex-row" key={i}>
                  <img
                    className="p-2 image-avatar"
                    alt="img"
                    src={
                      revenue.productDetails?.image
                        ? revenue.productDetails.image
                        : blank
                    }
                  />
                  <p className="p-2">
                    {revenue.productDetails?.name}
                    <br />
                    <span>{revenue.productDetails?.brand}</span>
                  </p>
                  <h6 className="ml-auto p-2">
                    ${revenue.total} <br />
                    {/* <span>Revenue</span> */}
                  </h6>
                </div>
              </>
            ))}
          </div>
        </div>
        <div className="col-md-6">
          <div className="card-chart">
            <div className="d-flex flex-row">
              <h6 className="mr-auto p-2">TOP SELLING PRODUCTS BY COUNT</h6>
              {/* <button className="btn p-2">View All</button> */}
            </div>

            {productsByCount.slice(0, 5).map((revenue, i) => {
              // console.log(revenue, 'revenue')
              return (
                <>
                  <div key={i} className="pt-2 d-flex flex-row">
                    <img
                      className="p-2 image-avatar"
                      alt="img"
                      src={
                        revenue.productDetails?.image
                          ? revenue.productDetails.image
                          : blank
                      }
                    />
                    <p className="p-2">
                      {revenue.productDetails?.name}
                      <br />
                      <span>{revenue.productDetails?.brand}</span>
                    </p>
                    <h6 className="ml-auto p-2">
                      {revenue.occurrence} <br />
                      {/* <span>Orders</span> */}
                    </h6>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>

      {/* <div className="row mt-3">
                <div className="col-md-6 ">
                </div>
                <div className="col-md-6">
                    <div className="card-chart">
                        <div className="d-flex flex-row">
                            <h6 className="mr-auto p-2">TOP SELLING PRODUCTS BY COUNT</h6>
                            <button className="btn p-2">View All</button>
                        </div>
                        {productsByCount.slice(0, 5).map((revenue, i) => {
                            // console.log(revenue, 'revenue')
                            return <>
                                <div key={i} className="pt-2 d-flex flex-row">
                                    <img className="p-2 image-avatar" alt="img" src="" />
                                    <p className="p-2">{revenue.productDetails?.name}<br /><span>{revenue.productDetails?.brand}</span></p>
                                    <h6 className="ml-auto p-2">$140 <br /><span>Revenue</span></h6>
                                </div>
                            </>
                        })}
                    </div>
                </div>
            </div> */}
    </>
  );
}
