/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import url from "../../config/axios";
import { useHistory } from "react-router-dom";
import storage from "../../config/firebase";
import Popup from "reactjs-popup";
import calculateAspectRatio from "calculate-aspect-ratio";

export default function AddBanner() {
  const history = useHistory();
  const [bannerTitle, setBannerTitle] = useState("");

  const [image, setImage] = useState("");
  const [time, setTime] = useState("");
  const [file, setFile] = useState("");
  const [link, setLink] = useState("");
  const [status, setStatus] = useState(false);
  const [outlets, setOutlets] = useState([]);
  const [outletId, setOutletId] = useState("");
  const [inProgress, setInProgress] = useState(false);
  const [resolutionError, setResolutionError] = useState(null);
  const allowedRatios = ["5:2"];
  // const [validationMessages, setvalidationMessages] = React.useState({
  //   title: "",
  //   link: "",
  //   time: 0,
  //   image: "",
  // });
  const [message, setMessage] = useState({
    display: false,
    status: false,
    text: "",
  });

  const ref = useRef();
  const openTooltip = () => ref.current.open();
  const closeTooltip = () => {
    try {
      ref.current.close();
      if (message.text === "Success") {
        history.push("/banner");
      }
    } catch (e) {
      //ignore
    }
  };

  const admin = JSON.parse(localStorage.getItem("Admin"));

  useEffect(() => {
    if (!admin[0].isSubOutlet) {
      GetAllOutlets();
    }
  }, []);

  const addBanner = (blob) => {
    let body = {
      consumerId: admin[0].consumerId,
      outletChainID: outletId,
      title: bannerTitle,
      image: blob,
      link: link,
      time: time,
      status: status,
    };
    if (admin[0].isSubOutlet) {
      body.outletChainID = admin[0].outletChainID;
    }
    url
      .post(`/banner/`, body)
      .then(async (res) => {
        setInProgress(false);
        if (res.data.Message === "Success") {
          // alert("Banner Added");
          setMessage({
            text: "Success",
            status: true,
            display: true,
          });
          openTooltip();
          // history.push("/banner");
        } else {
          setMessage({
            text: "Failed",
            status: true,
            display: true,
          });
          openTooltip();
        }
      })
      .catch((e) => {
        console.error(e);
        setInProgress(false);
      });
  };

  const fileUpload = () => {
    // const storageRef = ref(storage, `images/${file.name}`);
    // console.log(image);
    setTriedBefore(true);
    const gotError = validateForm();
    if (Object.keys(gotError).length === 0 && resolutionError === null) {
      setInProgress(true);
      if (file !== "") {
        const uploadTask = storage.ref(`images/${file.name}`).put(file);
        console.log("Uploading files....");
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // const progress = Math.round(
            //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            // );
          },
          (error) => {
            console.error(error);
          },
          () => {
            storage
              .ref("images")
              .child(file.name)
              .getDownloadURL()
              .then((blob) => {
                // console.log("Uploaded blob: ", blob);
                addBanner(blob);
              });
          }
        );
      } else {
        addBanner("");
      }
    }
  };

  const imageSelector = async (event) => {
    const allowedExtensions = ["png", "jpg", "jpeg", "gif", "webp"];

    if (event.target.files[0]) {
      // console.log(event);
      const splitted = (event.target.files[0]?.name + "").split(".");
      const extension = splitted[splitted.length - 1] ?? null;
      if (allowedExtensions.includes(extension)) {
        var i = new Image();
        i.onload = () => {
          try {
            const aspectRatio = calculateAspectRatio(i.width, i.height);
            console.log("ratio is: ", aspectRatio);
            if (allowedRatios.includes(aspectRatio + "")) {
              setResolutionError(null);
            } else {
              setResolutionError(
                `Allowed ratio(s) : ${allowedRatios.join(", ")}`
              );
            }
          } catch (e) {
            setResolutionError(`Invalid format`);
          }
        };
        const objectURL = URL.createObjectURL(event.target.files[0]);
        i.src = objectURL;
        setImage(objectURL);
        setFile(event.target.files[0]);
      }
    }
  };

  const GetAllOutlets = () => {
    url
      .get(`/oc/?consumerId=${admin[0].consumerId}`)
      .then(async (res) => {
        // console.log('res', res)
        if (res.data.Message === "Success") {
          setOutlets(res.data.data);
        } else {
          alert(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  };

  const [formValidationErrors, setFormValidationErrors] = useState({});
  const validateForm = () => {
    let data = { ...formValidationErrors };
    //manipulate banner title
    if (bannerTitle.trim().length === 0) {
      data = { ...data, btError: "Title of the banner is required" };
    } else {
      let { btError, ...others } = data;
      data = { ...others };
    }

    //manipulate time
    if ((time + "").trim().length === 0 || Number((time + "").trim()) <= 0) {
      data = { ...data, timeError: "A positive duration is required" };
    } else {
      let { timeError, ...others } = data;
      data = { ...others };
    }

    //manipulatelink
    if (link.trim().length !== 0) {
      try {
        new URL(link);
        let { linkError, ...others } = data;
        data = { ...others };
      } catch (e) {
        data = { ...data, linkError: "Enter a valid URL" };
      }
    } else {
      let { linkError, ...others } = data;
      data = { ...others };
    }

    // manipulate image
    if (image === "") {
      data = { ...data, imgError: "Image of the banner is required" };
    } else {
      let { imgError, ...others } = data;
      data = { ...others };
    }
    setFormValidationErrors({ ...data });
    return data;
  };
  const [triedBefore, setTriedBefore] = useState(false);
  useEffect(() => {
    if (triedBefore) {
      validateForm();
    }
  }, [time, link, bannerTitle, image, triedBefore]);
  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div>
              <div className="col-md-12">
                <h5>Banner Details</h5>
                <br></br>
              </div>

              <div className="col-md-12">
                {!admin[0].isSubOutlet && (
                  <div className="group-comb">
                    <div className="input-group">
                      <select
                        className="form-control"
                        onChange={(e) => setOutletId(e.target.value)}
                        id="exampleFormControlSelect1">
                        <option value="select">Select Outlet</option>
                        {outlets.map((outlet, i) => {
                          return (
                            <option value={outlet.outletChainID} key={i}>
                              {outlet.outletName}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                )}
              </div>

              <div className="col-md-12">
                <div className="group-comb">
                  <div className="form-group">
                    <label>Banner Title*</label>
                    <input
                      type="text"
                      value={bannerTitle}
                      onChange={(e) => setBannerTitle(e.target.value)}
                      className="form-control"
                      placeholder="Banner Title"
                    />
                  </div>
                  {formValidationErrors.btError ? (
                    <span className="validation-help">
                      {formValidationErrors.btError}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="col-md-12">
                <div className="group-comb">
                  <div className="form-group">
                    <label>Banner Duration*</label>
                    <input
                      type="number"
                      value={time}
                      onChange={(e) =>
                        setTime(e.target.value ? Math.abs(e.target.value) : "")
                      }
                      className="form-control"
                      placeholder="Banner Duration(seconds)"
                    />
                  </div>
                  {formValidationErrors.timeError ? (
                    <span className="validation-help">
                      {formValidationErrors.timeError}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="col-md-12">
                <div className="group-comb">
                  <div className="form-group">
                    <label>Banner Link*</label>
                    <input
                      type="text"
                      value={link}
                      onChange={(e) => setLink(e.target.value)}
                      className="form-control"
                      placeholder="Banner Link"
                    />
                  </div>
                  {formValidationErrors.linkError ? (
                    <span className="validation-help">
                      {formValidationErrors.linkError}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="col-md-12">
                <div className="custom-file">
                  <input
                    type="file"
                    className="custom-file-input"
                    onChange={imageSelector}
                    accept="image/*"
                    id="customFileLang"
                    lang="en"
                  />
                  <label
                    className="custom-file-label"
                    htmlFor="customFileLang"
                    style={{
                      borderRadius: "15px",
                      overflow: "hidden",
                    }}>
                    Select Banner
                  </label>
                </div>
                {formValidationErrors.imgError ? (
                  <span className="validation-help">
                    {formValidationErrors.imgError}
                  </span>
                ) : (
                  ""
                )}
                {resolutionError ? (
                  <span className="validation-help">{resolutionError}</span>
                ) : (
                  ""
                )}
              </div>

              {image !== "" && (
                <div className="col-md-12">
                  <div className="">
                    <img
                      src={image}
                      style={{ width: "100%", height: "auto" }}
                      alt="selectedImage"
                    />
                  </div>
                </div>
              )}
              <div className="col-md-12">
                <h5 style={{ fontWeight: "normal", fontSize: "100%" }}>
                  Status
                </h5>
              </div>
              <div className="col-md-6 switch-col">
                <label className="switch switch-demo">
                  <input
                    type="checkbox"
                    checked={status}
                    onChange={() => setStatus(!status)}
                  />
                  <span className="slider round"></span>
                  <span className="slider-text" style={{ paddingLeft: "81px" }}>
                    {status ? "Show" : "Hide"}
                  </span>
                </label>
              </div>
              <div className="col-lg-12">
                <button
                  type="button"
                  onClick={fileUpload}
                  disabled={inProgress}
                  className="btn btn-cannaby"
                  style={{ align: "center" }}>
                  {inProgress ? "Creating..." : "Create Banner"}
                </button>

                <button
                  style={{ marginLeft: "20px", marginTop: "30px" }}
                  type="button"
                  disabled={inProgress}
                  onClick={() => history.push("/banner")}
                  className="btn btn-rnav ">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Popup ref={ref} position="center" onClose={closeTooltip}>
        {message.status ? (
          <div style={{ textAlign: "center" }}>
            <i class="fa fa-check" style={{
              color: "green",
              fontSize: "70px",
            }}></i>
            <h2 className="popup-heading" style={{ marginTop: "20px" }}>
              Success
            </h2>
            <p>Banner added successfully</p>
            <button
              className="btn btn-cannaby"
              style={{ marginTop: "0" }}
              onClick={closeTooltip}>
              OK
            </button>
          </div>
        ) : (
          <div style={{ textAlign: "center" }}>
            <i
              style={{
                color: "red",
                fontSize: "70px",
              }}
              className="fa fa-ban"></i>
            <h2 style={{ marginTop: "20px" }}>Failed</h2>
            <button
              style={{ marginTop: "10px" }}
              type="button"
              onClick={closeTooltip}
              className="btn btn-primary">
              Try Again
            </button>
          </div>
        )}
      </Popup>
    </div>
  );
}
