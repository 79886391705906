/* eslint-disable no-unused-vars */
import React, { useRef } from "react";
import "./deals.css";
import url from "../../config/axios";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import Popup from "reactjs-popup";
import {
  FilterAlt,
  SettingsSystemDaydreamOutlined,
  Tune,
} from "@mui/icons-material";

export default function Index() {
  const history = useHistory();
  const [outlets, setOutlets] = useState([]);
  const [deals, setDeals] = useState([]);
  // const [sendDeal, setSendDeal] = useState(null)
  const [totalPage, setTotalPage] = useState("");
  const [minPro, setminPro] = useState("");
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [unfiltered, setUnfiltered] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  var perPage = 10;

  //clientSide search
  useEffect(
    () => {
      if (searchTerm.trim().length !== 0) {
        let filtered = unfiltered.filter((element) =>
          element.name.toLowerCase().includes(searchTerm.trim().toLowerCase())
        );
        setDeals(filtered);
        if (filtered.length === 0) {
          openNotFoundPopUp();
        }
        // setTotalPage(filtered.length);
        // setminPro(page * 50 - 50 + 1);
        setTotalPage(Math.ceil(filtered.length / perPage));
        setCurrentPage(1);
      } else {
        setDeals(unfiltered);
        // setTotalPage(unfiltered.length);
        // setminPro(page * 50 - 50 + 1);
        setTotalPage(Math.ceil(unfiltered.length / perPage));
        setCurrentPage(1);
      }
    },
    // eslint-disable-next-line
    [searchTerm]
  );
  const adminID = JSON.parse(localStorage.getItem("Admin"));

  const incrementPage = () => {
    // if (totalPage < 50) {
    // } else {
    //   setPage(page + 1);
    // }
    if (currentPage < totalPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const decrementPage = () => {
    // if (page > 1) {
    //   setPage(page - 1);
    // }
    if (currentPage >= 2) {
      setCurrentPage(currentPage - 1);
    }
  };

  useEffect(() => {
    GetAllOutlets();
    GetAllDeals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const GetAllOutlets = () => {
    const adminID = JSON.parse(localStorage.getItem("Admin"));
    // console.log(adminID[0].adminID);
    url
      .get(`/oc/?consumerId=${adminID[0].consumerId}`)
      .then(async (res) => {
        console.log("res", res);
        if (res.data.Message === "Success") {
          setOutlets(res.data.data);
        } else {
          alert(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  };

  // const SearchByDeal = (value) => {
  //   if (value === "") {
  //     GetAllDeals();
  //   }
  //   url
  //     .get(`/deal/search?consumerId=${adminID[0].consumerId}&name=${value}`)
  //     .then(async (res) => {
  //       // console.log(res, 'response:')
  //       if (res.data.Message === "Success") {
  //         let modified = res.data.data?.reverse() ?? [];
  //         setDeals(modified);
  //         setTotalPage(modified.length);
  //         setminPro(page * 50 - 50 + 1);
  //       } else {
  //         alert(res.data.data);
  //       }
  //     });
  // };
  const [message, setMessage] = useState({
    display: false,
    status: false,
    text: "",
  });
  const notFoundRef = useRef();
  const openNotFoundPopUp = () => notFoundRef.current.open();
  const closeNotFoundPopUp = () => {
    notFoundRef.current.close();
    setSearchTerm("");
  };
  const ref = useRef();
  const openTooltip = () => ref.current.open();
  const closeTooltip = (success = false) => {
    if (success) {
      ref.current.close();
      GetAllDeals();
    } else {
      ref.current.close();
    }
  };
  const [dealId, setDealId] = useState(null);
  const deleteDeal = () => {
    url.delete(`/deal/?dealId=${dealId}`).then(async (res) => {
      // console.log(res, 'Coupon Deleted')
      if (res.data.Message === "Success") {
        // openTooltip();
        closeTooltip(true);
      } else {
        alert(res.data.data);
      }
    });
  };
  const GetAllDeals = () => {
    url
      // .get(`/deal/all/?consumerId=${adminID[0].consumerId}`)
      .get(
        `/v1/deals/applicable-deals?consumerID=${adminID[0].consumerId
        }&hideDisabled=${-1}`
      )
      .then(async (res) => {
        if (res.data.Message === "Success") {
          let modified = res.data.data?.reverse() ?? [];
          setUnfiltered(modified);
          setDeals(modified);
          // setTotalPage(modified.length);
          // setminPro(page * 50 - 50 + 1);
          setTotalPage(Math.ceil(modified.length / perPage));
          setCurrentPage(1);
        } else {
          alert(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  };

  const GetDealsByOutlet = (outletId) => {
    const adminID = JSON.parse(localStorage.getItem("Admin"));
    if (outletId !== "select") {
      url
        .get(
          `/deal/outlet/?consumerId=${adminID[0].consumerId}&outletChainID=${outletId}`
        )
        .then(async (res) => {
          console.log("Deal Outlet:", res);
          if (res.data.Message === "Success") {
            let modified =
              res.data.data?.sort((obj1, obj2) =>
                new Date(obj1.endDate) > new Date(obj2.endDate) ? -1 : 1
              ) ?? [];
            setDeals(modified);
            // setTotalPage(modified.length);
            // setminPro(page * 50 - 50 + 1);
            setTotalPage(Math.ceil(modified.length / perPage));
            setCurrentPage(1);
          } else {
            alert(res.data.data);
          }
        })
        .catch((e) => console.log(e));
    } else {
      GetAllDeals();
    }
  };

  const editDeal = (data) => {
    localStorage.setItem("dealEdit", JSON.stringify(data));
    history.push("/edit-deal");
  };
  const color = ["#03b7a5", "#fc3986", "#ab48d5", "#2bb72b", "#bdd72a"];
  var num = 0;

  function assignColor() {
    var random = Math.floor(Math.random() * color.length);
    while (num === random) {
      random = Math.floor(Math.random() * color.length);
    }
    num = random;
    var value = color[random];
    console.log(value, "VALUES");
    return value;
  }
  return (
    <div className="row">
      {" "}
      <div className="col-md-12">
        <div className="card card-pro">
          {/* <button
            className="btn btn-primary btn-pro mr-5"
            onClick={() => {
              history.push("/add-deal");
            }}>
            <i className="fas fa-plus-circle"></i>
            Add Deals
          </button> */}
          {/* onClick={() => {
              history.push("/addCatalog");
            }} */}
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                {/* <div className="col-md-3">
                  <div className="group-comb">
                    <div className="input-group">
                      <select
                        className="form-control"
                        onChange={(e) => GetDealsByOutlet(e.target.value)}
                        id="exampleFormControlSelect1"
                      >
                        <option value="select">Select Outlet</option>
                        {outlets.map((outlet, i) => {
                          return (
                            <option value={outlet.outletChainID} key={i}>
                              {outlet.outletName}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div> */}
                <div className="col-md-6">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control table-search"
                      placeholder="Search deal name"
                      aria-label="Input group example"
                      aria-describedby="btnGroupAddon"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <div className="input-group-prepend">
                      <div
                        className="input-group-text table-search-icon"
                        id="btnGroupAddon"
                      >
                        <i className="fas fa-search"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-2 filters-bar">
                  {/* <div className="dropdown">
                    <a
                      className="btn cannaby-light"
                      href="/"
                      role="button"
                      id="dropdownMenuLink"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="fas fa-filter"></i>
                    </a>
                    <a
                      className="btn cannaby-light"
                      href="/"
                      role="button"
                      id="dropdownMenuLink"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <Tune style={{ transform: "rotate(90deg)" }} />
                    </a>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink"
                    >
                      <a className="dropdown-item" href="/">
                        Action
                      </a>
                      <a className="dropdown-item" href="/">
                        Another action
                      </a>
                      <a className="dropdown-item" href="/">
                        Something else here
                      </a>
                    </div>
                  </div>
                  <div className="dropdown"> */}
                  {/* <a
                      className="btn cannaby-light"
                      href="/"
                      role="button"
                      id="dropdownMenuLink"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="fas fa-filter"></i>
                    </a> */}

                  {/* <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink"
                    >
                      <a className="dropdown-item" href="/">
                        Action
                      </a>
                      <a className="dropdown-item" href="/">
                        Another action
                      </a>
                      <a className="dropdown-item" href="/">
                        Something else here
                      </a>
                    </div>
                  </div>*/}
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      history.push("/add-deal");
                    }}
                  >
                    <i className="fas fa-plus-circle"></i>
                    Add Deals
                  </button>
                </div>
                {!!deals.length && (
                  <div className="col-md-4 pagination">
                    <span>
                      {`${perPage * (currentPage - 1) + 1} - ${perPage * (currentPage - 1) +
                        deals.slice(
                          (currentPage - 1) * perPage,
                          currentPage * perPage
                        ).length
                        } of ${deals?.length ?? 0}`}
                    </span>
                    <div
                      className="btn cannaby-light"
                      onClick={() => decrementPage()}
                      role="button"
                    >
                      <i className="fas fa-chevron-left"></i>
                    </div>
                    <div
                      className="btn cannaby-light"
                      onClick={() => incrementPage()}
                      role="button"
                    >
                      <i className="fas fa-chevron-right"></i>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-12">
              <table className="table table-striped-cust">
                <thead>
                  <tr>
                    <th scope="col" nowrap="nowrap">
                      Name
                    </th>

                    {/* <th scope="col"></th>
                    <th scope="col"></th> */}
                    <th scope="col">Applicable to</th>
                    <th scope="col">Value</th>
                    <th scope="col">Expires On</th>
                    {/* <th scope="col">Usage</th> */}
                    <th scope="col">Status</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    deals.length !== 0 ?
                      deals
                        .slice((currentPage - 1) * perPage, currentPage * perPage)
                        .map((deal, i) => {
                          //   console.log(coupon, 'coupon:');
                          return (
                            <tr key={i}>
                              <td colSpan="1">
                                <div style={{ display: "flex" }}>
                                  {" "}
                                  {deal.image ? (
                                    <img
                                      img
                                      alt="yo1"
                                      src={deal.image}
                                      className="img-fluid "
                                      width="40"
                                      style={{ height: "40px" }}
                                    />) : (
                                    <div
                                      className="image-thumbnail-div"
                                      style={{
                                        backgroundColor: assignColor(),
                                        width: "40px",
                                        height: "40px",
                                        borderRadius: "5px",
                                      }}>
                                      <span >{deal?.name?.substring(0, 1)}</span>
                                    </div>
                                  )

                                  }
                                  <span style={{ paddingTop: "10px" }}>{deal.name}</span>
                                </div>
                              </td>
                              <td>
                                <div style={{ width: "150px" }}>
                                  {/* {deal.selectedProducts.length !== 0 &&
                              deal.selectedProducts.map((prod, i) => {
                                return <span key={i}>{prod.name}</span>;
                              })}
                            {deal.selectedCategories.length !== 0 &&
                              deal.selectedCategories.map((prod, i) => {
                                return <span key={i}>{prod.name}</span>;
                              })} */}
                                  {!!deal.selectedCategories?.length ? (
                                    <span>Selected Categories</span>
                                  ) : (
                                    <span>Selected Products</span>
                                  )}
                                </div>
                              </td>
                              <td>{deal.discountValue} %</td>
                              <td>
                                {!!deal.neverExpires
                                  ? "Never Expires"
                                  : moment(deal.endDate).format(
                                    "MMMM Do YYYY h:mm A"
                                  )}
                              </td>
                              {/* <td className="status">
                          <span>{deal.name}</span>
                        </td> */}
                              <td
                                className={deal.status ? "color-blue" : "color-red"}
                              >
                                <span>{deal.status ? "Enabled" : "Disabled"} </span>
                              </td>
                              <td>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "2em",
                                  }}
                                >
                                  <i
                                    className="fas fa-pen"
                                    onClick={() => {
                                      editDeal(deal);
                                    }}
                                  ></i>
                                  {/* onClick={() => moveToEdit(product)} */}
                                  <i
                                    className="fa fa-trash-alt"
                                    style={{ color: "red", cursor: "pointer" }}
                                    onClick={() => {
                                      setDealId(deal.dealId);
                                      openTooltip();
                                    }}
                                  ></i>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      :
                      <>
                        <tr>
                          <td colSpan="12" style={{ textAlign: "center" }}>
                            No Deals found</td></tr>
                      </>}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Popup ref={ref} position="center">
        <div style={{ textAlign: "center" }}>
          <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
          <h4 className="popup-heading">Do you want to delete this deal ?</h4>
          <button
            className="btn btn-cannaby"
            onClick={() => {
              deleteDeal();
            }}
          >
            Yes
          </button>
          <button
            style={{ marginLeft: "20px", marginTop: "30px" }}
            type="button"
            className="btn popup-cancel-button"
            onClick={closeTooltip}
          >
            No
          </button>
        </div>
      </Popup>
      <Popup ref={notFoundRef} position="center">
        <div style={{ textAlign: "center" }}>
          <i
            style={{
              color: "green",
              fontSize: "70px",
            }}
            // style={{
            //   color: "green",
            //   fontSize: "60px",
            //   border: "10px solid green",
            //   borderRadius: "50px",
            //   padding: "10px",
            // }}
            class="fa fa-ban"
          ></i>
          <h2 className="popup-heading" style={{ marginTop: "20px" }}>
            Failed
          </h2>
          <p>Deals not found</p>
          <button
            className="btn btn-cannaby"
            style={{ marginTop: "0" }}
            onClick={closeNotFoundPopUp}
          >
            OK
          </button>
        </div>
      </Popup>
    </div>
  );
}
