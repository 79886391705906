import { useState, useEffect, Fragment } from "react";
import { useHistory } from "react-router-dom";
import TimePicker from "@mui/lab/TimePicker";
import TextField from "@mui/material/TextField";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
import url from "../../../config/axios";

function BasicInfoPage({ invokeLogout }) {
  const [formData, setFormData] = useState({
    siteTitle: "",
    supportNumber: "",
    supportEmail: "",
    address: "",
    state: "",
    country: "",
    longitude: "",
    latitude: "",
    copyright: "",
    regularTimingFrom: new Date(new Date().setHours(8)),
    regularTimingTo: new Date(new Date().setHours(10)),
    assessmentFee: 200,
  });
  const [inProgress, setInProgress] = useState(true);
  const handleChangeFromEvent = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const [formValidationErrors, setFormvalidationErrors] = useState({});
  const validateForm = () => {
    console.log("from", formData.regularTimingFrom);
    console.log("to", formData.regularTimingTo);
    let data = { ...formValidationErrors };
    //manipualte starting from time
    if (new Date(formData.regularTimingFrom).toString() === "Invalid Date") {
      data = { ...data, rtfError: "Invalid time" };
    } else if (
      new Date(formData.regularTimingFrom) > new Date(formData.regularTimingTo)
    ) {
      data = { ...data, rtfError: "Invalid timeslot" };
    } else {
      const { rtfError, ...others } = data;
      data = { ...others };
    }
    //manipulate ending to time
    if (new Date(formData.regularTimingTo).toString() === "Invalid Date") {
      data = { ...data, rttError: "Invalid time" };
    } else if (
      new Date(formData.regularTimingFrom) > new Date(formData.regularTimingTo)
    ) {
      data = {
        ...data,
        rttError: "Invalid time slot",
      };
    } else {
      let { rttError, ...others } = data;
      data = { ...others };
    }
    // console.log("*************Manipulated date is: ", data);

    //manipulate assessment fee
    if (!formData.assessmentFee) {
      data = { ...data, afError: "Assessment fee is required" };
    } else if (+formData.assessmentFee < 0) {
      data = { ...data, afError: "Must be a positive value" };
    } else {
      let { afError, ...others } = data;
      data = { ...others };
    }

    //manipulate copyright
    if (formData.copyright.trim().length === 0) {
      data = { ...data, cpError: "Copyright text required" };
    } else {
      let { cpError, ...others } = data;
      data = { ...others };
    }

    //manipulate logitude
    if ((formData.longitude + "").trim().length === 0) {
      data = { ...data, longError: "longitude is required" };
    } else {
      let { longError, ...others } = data;
      data = { ...others };
    }

    //manipulate copyright
    if ((formData.latitude + "").trim().length === 0) {
      data = { ...data, latError: "latitude is required" };
    } else {
      let { latError, ...others } = data;
      data = { ...others };
    }

    //manipulate country
    if (formData.country.trim().length === 0) {
      data = { ...data, countryError: "Country is required" };
    } else {
      let { countryError, ...others } = data;
      data = { ...others };
    }

    //manipulate state
    if (formData.state.trim().length === 0) {
      data = { ...data, stateError: "State is required" };
    } else {
      let { stateError, ...others } = data;
      data = { ...others };
    }

    //manipulate address
    if (formData.address.trim().length === 0) {
      data = { ...data, addressError: "Address is required" };
    } else {
      let { addressError, ...others } = data;
      data = { ...others };
    }

    //manipulate email
    if (
      !formData.supportEmail
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      data = { ...data, semailError: "Enter a valid email" };
    } else {
      let { semailError, ...others } = data;
      data = { ...others };
    }

    //manipulate support number
    if (formData.supportNumber.trim().length === 0) {
      data = { ...data, spNumberError: "Support Number is required" };
    } else {
      let { spNumberError, ...others } = data;
      data = { ...others };
    }

    if (formData.siteTitle.trim().length === 0) {
      data = { ...data, stError: "Site title is required" };
    } else {
      let { stError, ...others } = data;
      data = { ...others };
    }

    setFormvalidationErrors({ ...data });
    return data;
  };
  const [triedBefore, setTriedBefore] = useState(false);
  const updateBasicInfo = () => {
    setTriedBefore(true);
    let errors = validateForm();
    if (!Object.keys(errors).length) {
      setInProgress(true);
      const body = {
        outletName: formData.siteTitle,
        opensAt: new Date(formData.regularTimingFrom),
        closesAt: new Date(formData.regularTimingTo),
        latitude: formData.latitude,
        longitude: formData.longitude,
        copyrightText: formData.copyright,
        assessmentFee: formData.assessmentFee,
        helpEmail: formData.supportEmail,
        helpContact: formData.supportNumber,
        country: formData.country,
        state: formData.state,
        address: formData.address,
      };
      // console.log("Body is: ", JSON.stringify(body));
      url
        .patch(`/v1/general-settings/basic-info`, body)
        .then((res) => {
          if (res?.data?.Message === "Success") {
            updateLocalStorage(res.data?.data);
          }
          setInProgress(false);
        })
        .catch((e) => {
          setInProgress(false);
          if (+e?.response?.status === 401) {
            invokeLogout();
          }
        });
    }
  };
  useEffect(
    () => {
      if (triedBefore) {
        validateForm();
      }
    },
    //eslint-disable-next-line
    [formData, triedBefore]
  );

  //fetch previous data
  useEffect(() => {
    const adminID = JSON.parse(localStorage.getItem("Admin"));
    const outletChainID = adminID[0]?.outletChainID ?? null;
    // console.log("Outlet chain id is: ", outletChainID);
    url
      .get(`/v1/general-settings/basic-info?outletChainID=${outletChainID}`)
      .then((res) => {
        const response = res.data.data;
        setFormData({
          siteTitle: response?.outletName ?? "",
          supportNumber: response?.helpContact ?? "",
          supportEmail: response?.helpEmail ?? "",
          address: response?.address ?? "",
          state: response?.state ?? "",
          country: response?.country ?? "",
          longitude: response?.location?.coordinates[0] ?? 0,
          latitude: response?.location?.coordinates[1] ?? 0,
          copyright: response?.copyrightText ?? "",
          regularTimingFrom:
            response?.opensAt ?? new Date(new Date().setHours(8)),
          regularTimingTo:
            response?.closesAt ?? new Date(new Date().setHours(10)),
          assessmentFee: response?.assessmentFee ?? 0,
        });
        setInProgress(false);
      })
      .catch((e) => {
        setInProgress(false);
        console.error(e);
      });
  }, []);

  const history = useHistory();

  const handleChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  /**
   *
   * @param {object} data basic info
   */
  const updateLocalStorage = async (data) => {
    try {
      console.log("Here...");
      const prevData = await JSON.parse(localStorage.getItem("Admin"));
      if (Array.isArray(prevData)) {
        let { media } = prevData[0];
        prevData[0] = { ...data, media };
        console.log("Prev data is: ", prevData);
        await localStorage.setItem("Admin", JSON.stringify(prevData));
        window.location.reload();
      }
    } catch (e) {}
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "1em" }}>
      {inProgress ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <CircularProgress />
        </div>
      ) : (
        <Fragment>
          <div className="row" style={{ justifyContent: "space-between" }}>
            <div className="col-md-6">
              <div style={{ width: "95%" }}>
                <div className="group-comb">
                  <div className="form-group">
                    <label>Site Title</label>
                    <input
                      type="text"
                      name="siteTitle"
                      value={formData.siteTitle}
                      onChange={handleChangeFromEvent}
                      className="form-control"
                      placeholder="Site Title"
                    />
                  </div>
                  {formValidationErrors.stError ? (
                    <span className="validation-help">
                      {formValidationErrors.stError}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div style={{ width: "95%", marginLeft: "auto" }}>
                <div className="group-comb">
                  <div className="form-group">
                    <label>Suppport Number</label>
                    <input
                      type="number"
                      name="supportNumber"
                      value={formData.supportNumber}
                      onKeyPress={(e) => {
                        var charCode =
                          typeof e.which == "undefined" ? e.keyCode : e.which;
                        var charStr = String.fromCharCode(charCode);

                        if (!charStr.match(/^[0-9]+$/)) e.preventDefault();
                      }}
                      onChange={(e) =>
                        handleChange(
                          "supportNumber",
                          !!e.target.value ? Math.abs(e.target.value) + "" : ""
                        )
                      }
                      className="form-control"
                      placeholder="Support Number"
                    />
                  </div>
                  {formValidationErrors.spNumberError ? (
                    <span className="validation-help">
                      {formValidationErrors.spNumberError}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="row" style={{ justifyContent: "space-between" }}>
            <div className="col-md-6">
              <div style={{ width: "95%" }}>
                <div className="group-comb">
                  <div className="form-group">
                    <label>Support Email</label>
                    <input
                      type="email"
                      name="supportEmail"
                      value={formData.supportEmail}
                      onChange={handleChangeFromEvent}
                      className="form-control"
                      placeholder="Support email"
                    />
                  </div>
                  {formValidationErrors.semailError ? (
                    <span className="validation-help">
                      {formValidationErrors.semailError}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div style={{ width: "95%", marginLeft: "auto" }}>
                <div className="group-comb">
                  <div className="form-group">
                    <label>Address</label>
                    <input
                      type="text"
                      name="address"
                      value={formData.address}
                      onChange={handleChangeFromEvent}
                      className="form-control"
                      placeholder="Address"
                    />
                  </div>
                  {formValidationErrors.addressError ? (
                    <span className="validation-help">
                      {formValidationErrors.addressError}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="row" style={{ justifyContent: "space-between" }}>
            <div className="col-md-6">
              <div style={{ width: "95%" }}>
                <div className="group-comb">
                  <div className="form-group">
                    <label>State</label>
                    <input
                      type="text"
                      name="state"
                      value={formData.state}
                      onChange={handleChangeFromEvent}
                      className="form-control"
                      placeholder="State"
                    />
                  </div>
                  {formValidationErrors.stateError ? (
                    <span className="validation-help">
                      {formValidationErrors.stateError}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div style={{ width: "95%", marginLeft: "auto" }}>
                <div className="group-comb">
                  <div className="form-group">
                    <label>Country</label>
                    <input
                      type="text"
                      value={formData.country}
                      name="country"
                      onChange={handleChangeFromEvent}
                      className="form-control"
                      placeholder="Country"
                    />
                  </div>
                  {formValidationErrors.countryError ? (
                    <span className="validation-help">
                      {formValidationErrors.countryError}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="row" style={{ justifyContent: "space-between" }}>
            <div className="col-md-6">
              <div style={{ width: "95%" }}>
                <div className="group-comb">
                  <div className="form-group">
                    <label>Longitude</label>
                    <input
                      type="text"
                      name="longitude"
                      value={formData.longitude}
                      onChange={(e) =>
                        handleChange("longitude", e.target.value + "")
                      }
                      className="form-control"
                      placeholder="Longitude"
                    />
                  </div>
                  {formValidationErrors.longError ? (
                    <span className="validation-help">
                      {formValidationErrors.longError}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div style={{ width: "95%", marginLeft: "auto" }}>
                <div className="group-comb">
                  <div className="form-group">
                    <label>Latitude</label>
                    <input
                      type="number"
                      value={formData.latitude}
                      name="latitude"
                      onChange={(e) =>
                        handleChange("latitude", e.target.value + "")
                      }
                      className="form-control"
                      placeholder="Latitude"
                    />
                  </div>
                  {formValidationErrors.latError ? (
                    <span className="validation-help">
                      {formValidationErrors.latError}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="group-comb">
                <div className="form-group">
                  <label>Copyright</label>
                  <input
                    type="text"
                    value={formData.copyright}
                    name="copyright"
                    onChange={handleChangeFromEvent}
                    className="form-control"
                    placeholder="Copyright"
                  />
                </div>
                {formValidationErrors.cpError ? (
                  <span className="validation-help">
                    {formValidationErrors.cpError}
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div
            className="row"
            style={{
              alignItems: "flex-end",
              flexWrap: "wrap",
            }}>
            <div
              className="col-md-5"
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "0.5em",
              }}>
              <span style={{ marginLeft: "1em", fontWeight: "bold" }}>
                Fees
              </span>
              <div className="group-comb">
                <div className="form-group" style={{ position: "relative" }}>
                  <label>Assessment Fee</label>
                  <input
                    type="number"
                    name="assessmentFee"
                    value={formData.assessmentFee}
                    className="form-control"
                    placeholder="Assessment Fee"
                    style={{ marginLeft: "0.5em" }}
                    onKeyPress={(e) => {
                      var charCode =
                        typeof e.which == "undefined" ? e.keyCode : e.which;
                      var charStr = String.fromCharCode(charCode);

                      if (!charStr.match(/^[0-9]+$/)) e.preventDefault();
                    }}
                    onChange={(e) => {
                      if (e.target.value) {
                        handleChange(
                          "assessmentFee",
                          Math.abs(e.target.value) + ""
                        );
                      } else {
                        handleChange("assessmentFee", "");
                      }
                    }}
                  />
                  {!!formData.assessmentFee && (
                    <span
                      style={{
                        position: "absolute",
                        top: "53%",
                        left: "2%",
                      }}>
                      $
                    </span>
                  )}
                </div>
                {formValidationErrors.afError ? (
                  <span className="validation-help">
                    {formValidationErrors.afError}
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div
              className="col-md-5"
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "0.5em",
                marginLeft: "auto",
              }}>
              <span style={{ marginLeft: "1em", fontWeight: "bold" }}>
                Outlet Timings
              </span>
              <div className="row">
                <div className="col-md-6">
                  <div className="group-comb">
                    <div className="form-group">
                      <label>From</label>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TimePicker
                          onChangeRaw={(e) => e.preventDefault()}
                          style={{ width: "100%" }}
                          value={formData.regularTimingFrom}
                          onChange={(value) => {
                            handleChange("regularTimingFrom", value);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              value={formData.regularTimingFrom}
                              name="regularTimingFrom"
                              onChange={(e) => {
                                let value =
                                  moment(new Date()).format("ddd DD-MMM-YYYY") +
                                  "," +
                                  e.target.value.toUpperCase();
                                // console.log(value);
                                handleChange(
                                  "regularTimingFrom",
                                  new Date(value)
                                );
                              }}
                              error={!!formValidationErrors.rtfError}
                              helperText={formValidationErrors.rtfError}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                    {/* {formValidationErrors.stError ? (
                    <span className="validation-help">
                      {formValidationErrors.stError}
                    </span>
                  ) : (
                    ""
                  )} */}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="group-comb">
                    <div className="form-group">
                      <label>To</label>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TimePicker
                          onChangeRaw={(e) => e.preventDefault()}
                          style={{ width: "100%" }}
                          value={formData.regularTimingTo}
                          onChange={(value) => {
                            handleChange("regularTimingTo", value);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              value={formData.regularTimingTo}
                              name="regularTimingTo"
                              onChange={(e) => {
                                let value =
                                  moment(new Date()).format("ddd DD-MMM-YYYY") +
                                  "," +
                                  e.target.value.toUpperCase();
                                // console.log(value);
                                handleChange(
                                  "regularTimingTo",
                                  new Date(value)
                                );
                              }}
                              error={!!formValidationErrors.rttError}
                              helperText={formValidationErrors.rttError}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                    {/* {formValidationErrors.stError ? (
                    <span className="validation-help">
                      {formValidationErrors.stError}
                    </span>
                  ) : (
                    ""
                  )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="row"
            style={{
              justifyContent: "center",
              alignItems: "center",
              gap: "2em",
              marginTop: "3em",
            }}>
            <button
              onClick={() => history.goBack()}
              style={{
                padding: "0.5em 3em",
                border: "1px solid blue",
                borderRadius: "5px",
                fontWeight: "bold",
                cursor: "pointer",
              }}>
              Cancel
            </button>
            <button
              onClick={updateBasicInfo}
              style={{
                padding: "0.5em 3em",
                borderRadius: "5px",
                fontWeight: "bold",
                color: "white",
                backgroundColor: "#294d94",
                border: "none",
                cursor: "pointer",
              }}>
              Update
            </button>
          </div>
        </Fragment>
      )}
    </div>
  );
}

export default BasicInfoPage;
