import axios from "axios";

const customInstance = axios.create({
  // baseURL: "https://happydazecannabis.herokuapp.com/",

  // baseURL: "https://happy-daze-backend.herokuapp.com/",
  // baseURL: "https://happy-daze-backend.herokuapp.com/",
  // baseURL: "http://localhost:8090/",
  baseURL: "https://happydaze.bleaum.dev/",
  headers: { Accept: "application/json" },
  withCredentials: true,
});

export default customInstance;
